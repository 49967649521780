<template>
    <div class="content">
        <div class="m-card">

            <div class="m-whitebox">
                <Row  class="list-border" :gutter="8">
                    <i-col span="4">手机号</i-col>
                    <i-col span="14">{{userInfo.phone}}</i-col>
                    <i-col span="6" class="f-tar" :push="userInfo.phone? 0 : 14">
<!--                        <a @click="$router.push('/host/index/account/bind')">更改手机号</a>-->
                        <Button type="primary" style="width: 100px;height: 36px;"  @click="$router.push(`/user/password/bind?exhibitionId=${exhibitionId}`)">更改手机号</Button>
                    </i-col>
                </Row>
<!--                <Row v-else class="list-border" :gutter="8">-->
<!--                    <i-col span="4">手机号</i-col>-->
<!--                    <i-col span="14">{{host.phoneNumber}}</i-col>-->
<!--                    <i-col span="6" class="f-tar">-->
<!--                        <a>绑定手机号</a>-->
<!--                    </i-col>-->
<!--                </Row>-->
                <Row class="list-border" :gutter="8">
                    <i-col span="4">登录密码</i-col>
                    <i-col span="6" push="14" class="f-tar">
                        <Button type="primary" style="width: 100px;height: 36px;"  @click="$router.push(`/user/password/bind-edit?exhibitionId=${exhibitionId}`)">更改密码</Button>
<!--                        <a @click="$router.push('/host/index/account/bind-edit')">更改密码</a>-->
                    </i-col>
                </Row>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "user",
        data(){
            return{

            }
        },
        computed:{
            ...mapGetters({
                userInfo: "getUser",
                exhibitionId: "getExhibitionId",
            }),
        }

    }
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
    .content {
        min-height: 700px;
        .m-card {
            border: solid 1px #ededed;
            .mc-top-title::before {
                opacity: 0;
                font-size: 16px;
            }
        }
        .m-whitebox {
            min-width: 900px;
            background: #fff;
            min-height: 500px;
            margin: 24px;
        }
        .list-border {
            line-height: 80px;
            border-bottom: 1px solid rgb(221, 221, 221);
            padding: 10px 0;
        }
        .account-box {
            padding-top: 20px;
            padding-left: 60px;
            width: 330px;
            & > p {
                margin-bottom: 1em;
                text-indent: 4px;
            }
        }
        .button-box {
            margin-top: 20px;
            button {
                width: 74px;
                margin-right: 5px;
            }
        }
    }
</style>
